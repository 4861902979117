body {
	overflow-y: scroll;
}

#root {
	padding-top: 130px;
}

.mb-30 {
	margin-bottom: 30px;
}

.bold {
	font-weight: 600 !important;
}

hr {
	margin: 15px 0 15px 0;
}

.fixed {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
}

.scroll-area {
	max-width: 100%;
	overflow-x: auto;
}

.mui-container {
	max-width: 980px;
}

.mui-appbar {
	z-index: 102;
}
.mui-appbar h1 {
	margin-top: 12px;
}

.mui-dropdown.menu .mui-caret {
	display: none;
}
.mui-dropdown.menu > button {
	margin: 5px 0 0 0;
	padding: 0 12px;
	height: auto;
}
.mui-dropdown.menu > button > span {
	font-size: 30px;
	line-height: 55px;
	font-weight: bold;
}
.mui-dropdown.menu > ul > li {
	font-size: 16px;
	cursor: pointer;
}
.mui-dropdown.menu > ul > li > a {
	padding-top: 5px;
	padding-bottom: 5px;
}

#searchForm {
	top: 62px;
	z-index: 101;
	background: rgba(255, 255, 255, .95);
}
#searchForm input {
	font-size: 24px;
	font-weight: bold;
	color: rgba(255, 64, 129, .87);
}

.mui-panel.active, .quoteItem.active, .quoteItem:hover {
	background: rgba(33, 150, 243, .1);
}

.mui-panel textarea {
	font-weight: bold;
}

.mui-panel > ul.mui-list--unstyled {
	margin-bottom: 0;
}

.quoteItem > strong:before, .quoteItem > strong:after {
	content: ' ❤ ';
	color: rgba(255, 64, 129, .87);
}

.quoteItem > div > span {
	visibility: hidden;
}
.quoteItem:hover > div > span {
	visibility: visible;
}

.quoteItem > pre {
	white-space: normal;
}
.quoteItem > pre li {
	display: inline;
}
.quoteItem > pre li > span {
	color: #333;
	cursor: pointer;
}
.quoteItem > pre li:after {
	content: ", ";
}
.quoteItem > pre li:last-child:after {
	content: "";
}
.quoteItem > pre li > span:hover,
.quoteItem > pre li > span:focus {
	text-decoration: underline;
}

.addQuoteBtn {
	position: fixed;
	bottom: 15px;
	right: 15px;
}

.quoteForm > textarea {
  height: 72px;
  resize: none;
}

.chars-total {
  margin-left: 15px;
}

.reloadBtn {
	margin-top: 12px;
}

.actions {
	cursor: pointer;
	padding: 5px 0;
}
.toggleBtn {
	font-size: 20px;
	font-family: monospace;
	line-height: 0;
	padding: 0 6px;
	margin-top: 20px;
}

.mui-panel.stats .mui-container {
	padding-left: 0;
}
.mui-panel.stats ul {
	margin-bottom: 0;
}
.mui-panel.stats li > pre {
	font-size: 13px;
	margin: 3px 0;
}

.mui-panel.net-error {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 5px;
  cursor: pointer;
}

.lighter {
	opacity: .75;
}

.mt0 {
	margin-top: 0;
}
.mb5 {
	margin-bottom: 5px;
}

.spinner {
	display: block;
	margin: 15px auto;
    border: 10px solid rgb(33, 150, 243);
    border-top: 10px solid rgb(255, 64, 129);
    border-bottom: 10px solid rgb(255, 64, 129);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spinner 2s linear infinite;
}
@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (max-width: 767px) {	/* xs */
	.quoteItem > .mui--text-headline {
		font-size: 20px;
	}
}
